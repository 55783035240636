<template>
  <div>
    <admin-title></admin-title>
    <div  class="container">
      <div class="page-title">
        <span>アカウント</span>
      </div>
      <div class="register">
        <div @click="toForm()" class="register-button">新規登録</div>
        <el-input placeholder="担当者" v-model="input3" class="input-with-select">

          <el-button @click="getList()" slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </div>

      <div  class="table-box">
        <div class="table-content">
          <div class="table-c">
            <el-table
                :data="tableData">
              <el-table-column
                  prop="username"
                  label="担当者"
                  show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                  prop="mail"
                  label="ログインID">
              </el-table-column>
              <el-table-column
                  label="管理者">
                <template slot-scope="scope">
                  <template v-if="scope.row.isAdmin == 1">
                    <el-checkbox class="checkbox" checked disabled></el-checkbox>
                  </template>
                </template>
              </el-table-column>
              <el-table-column label="" min-width="40rem">
                <template slot-scope="scope">
                    <div class="buttons">
                      <div @click="toEdit(scope.row)" class="button">
                        <img src="../../assets/images/account/edit.png">
                        <span>編集</span>
                      </div>
                      <div class="button delete" v-if="scope.row.isAdmin != 1" @click="handleDelete(scope.$index, scope.row)">
                        <img src="../../assets/images/delete.png"/>
                        <span>削除</span>
                      </div>
                    </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import { LOCAL_STORAGE_KEY } from "@/constants/common.js";
export default {
  name: "account",

  data() {


    return {
      form: {},

      input3: '',

      type: 1,

      status: 0,

      userInfo: {},

      tableData: [],

      detail: {},


    }
  },

  mounted() {
    console.log("###");

    this.userInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY.USER_INFO));




      this.getList();


  },

  methods: {
    search() {

    },
    toForm(){
      this.$router.push("accountForm");
    },
    toEdit(row){
      this.$router.push("accountForm?id="+row.id);
    },
    async getList() {

      let res = await this.$axios({url: `/compUser/list`, data: {token: this.userInfo.token, 'searchText': this.input3}, method: "post"});
      if(res.code == '000') {
        this.tableData = res.data;
      }
    },
    handleDelete(index, row) {
      console.log(row);
      let that = this;
      this.$confirm('「'+row.username+'」を<br/>削除してよろしいですか？', '', {
        confirmButtonText: 'はい',
        showCancelButton: 'キャンセル',
        center: true,
        dangerouslyUseHTMLString: true
      }).then(() => {

        that.deleteItem(row);

      }).catch(() => {
        // this.detail.isAdmin = true;
      });
    },

    async deleteItem(row) {
      let res = await this.$axios({url: `/compUser/delete`, data: {token: this.userInfo.token, id: row.id}, method: "post"});
      if(res.code == '000') {
        this.$message({
          type: 'success',
          message: res.message
        });
        this.getList();
      }
    } ,

    register() {
      let that = this;

      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          that.add();
        } else {
          console.log(valid)

          return false;
        }
      });
      // this.$confirm('管理者権限が外れています。\n' +
      //     'よろしいですか？', {
      //   confirmButtonText: 'は い',
      //   cancelButtonText: 'キャンセル',
      //   showClose: false
      // }).then(() => {
      //   this.$message({
      //     type: 'success',
      //     message: '删除成功!'
      //   });
      // }).catch(() => {
      //
      //
      //   this.$message({
      //     type: 'info',
      //     message: '已取消删除'
      //   });
      // });

    },

    async add() {
      if(this.type == 2) {
        let res = await this.$axios({url: `/compUser/add`, data: {
            token: this.userInfo.token,
            username: this.detail.username,
            password: this.detail.password,
            mail: this.detail.mail,
            isAdmin: this.detail.isAdmin ? 1 : 0
          }, method: "post"});

        if(res.code == '000') {
          this.$message({
            type: 'success',
            message: res.message
          });
          this.type = 1;
          this.getList();
        }
      } else if (this.type == 3) {
        let data = {
          token: this.userInfo.token,
          username: this.detail.username,
          id: this.detail.id,
          isAdmin: this.detail.isAdmin ? 1 : 0
        }

        if(this.detail.password) {
          data.password = this.detail.password;
        }

        let res = await this.$axios({url: `/compUser/update`, data: data, method: "post"});

        if(res.code == '000') {
          this.$message({
            type: 'success',
            message: res.message
          });
          this.type = 1;
          this.getList();
        }
      }
    }




  }
}
</script>

<style scoped src="../../style/account.css">

</style>
